export const howFill = [
  '1、您有1次提问、5次追问机会。请提供尽可能详尽的真实信息，每次最多可上传500字、9张图片。',
  '2、请确认图片清晰度，且已遮盖姓名、地址、电话等所有非疾病相关性隐私信息。',
  '3、请及时更新并上传“健康档案”资料，便于医生为您推荐更优方案和规避不利之处。',
];


/**
 * @description 我的页面九宫格
 */
export const mySeekList = [
  {
    to: '/doctorOrder/1',
    title: '待接诊',
    img: require('@/assets/ico-toseek.png'),
    isDoctor: 1,
  },
  {
    to: '/doctorOrder/2',
    title: '待回复',
    img: require('@/assets/ico-toreply.png'),
    isDoctor: 1,
  },
  {
    to: '/patientOrder/0',
    title: '待支付',
    img: require('@/assets/ico-torate.png'),
    isDoctor: 0,
  },
  {
    to: '/patientOrder/1',
    title: '待接诊',
    img: require('@/assets/ico-toseek.png'),
    isDoctor: 0,
  },
  {
    to: '/patientOrder/4',
    title: '进行中',
    img: require('@/assets/ico-toreply.png'),
    isDoctor: 0,
  },
  {
    to: '/doctorOrder/3',
    title: '待评价',
    img: require('@/assets/ico-torate.png'),
    isDoctor: 1,

  },
  {
    title: '问诊订单',
    img: require('@/assets/ico-toorder.png'),
  },
];
export const myGridList = [
  {
    to: '/doctorOrder/all',
    title: '问诊订单',
    img: require('@/assets/ico-order.png'),
    isDoctor: 1,
  },
  {
    to: '/doctorPlusOrder',
    title: '加号审核',
    img: require('@/assets/my-ico-add.png'),
    isDoctor: 1,
    round: 'plusCount',
  },
  // {
  //   to: '#',
  //   title: '处方记录',
  //   img: require('@/assets/my-ico-pre.png'),
  //   isDoctor: 1
  // },
  {
    to: '/caseList',
    title: '关注病例',
    img: require('@/assets/my-ico-collect.png'),
    isDoctor: 1,
  },
  {
    to: '/follow',
    title: '我的患者',
    img: require('@/assets/my-ico-patient.png'),
    isDoctor: 1,
  },
  {
    to: '/follow',
    title: '我的医生',
    img: require('@/assets/my-ico-doctor.png'),
    isDoctor: 0,
  },
  {
    to: '/patientMyFollow',
    title: '我的关注',
    img: require('@/assets/ico-my-patientfollow.png'),
    isDoctor: 0,
  },
  {
    to: '/patientPlusOrder',
    title: '门诊加号',
    img: require('@/assets/my-ico-plus.png'),
    isDoctor: 0,
  },
  // {
  //   to: '#',
  //   title: '我的处方',
  //   img: require('@/assets/my-ico-pre.png'),
  //   isDoctor: 0
  // },

  {
    to: '/archivesList',
    title: '健康档案',
    img: require('@/assets/my-ico-archives.png'),
    isDoctor: 0,
  },
  // {
  //   to: '#',
  //   title: '购药订单',
  //   img: require('@/assets/my-ico-archives.png'),
  //   isDoctor: 0
  // },
  // {
  //   to: '#',
  //   title: '收货地址',
  //   img: require('@/assets/my-ico-archives.png'),
  //   isDoctor: 0
  // },
  {
    to: '/mindList',
    title: '心意',
    img: require('@/assets/my-ico-mind.png'),
  },
  {
    to: '/commentList',
    title: '评价',
    img: require('@/assets/my-ico-rate.png'),
    round: 'daiPingLunCount',
  },
  {
    to: '/commentRepresentationsList',
    title: '评价申诉',
    img: require('@/assets/my-ico-repre.png'),
    isDoctor: 1,
  },
  // {
  //   to: '#',
  //   title: 'AI问诊',
  //   img: require('@/assets/my-ico-ai.png'),
  //   isDoctor: 0
  // },
  {
    to: '/myRecruit',
    title: '招募审核',
    img: require('@/assets/my-ico-apply.png'),
    round: 'daiZMCount',
  },
  // {
  //   to: '#',
  //   title: '招募申请',
  //   img: require('@/assets/my-ico-apply.png'),
  //   isDoctor:0
  // },
  // {
  //   to: '/jobList',
  //   title: '招聘管理',
  //   img: require('@/assets/my-job-list.png'),
  //   isDoctor: 1,
  // }
];

/**
 * @description 出诊类型
 */
export const visitList = ['专家', '普通', '特需'];


/**
 * @description
 */
export const doctorApplyData = {
  name: '',
  hospitalId: null,
  hospitalName: null,
  departmentId: null,
  departmentName: null,
  region: '',
  title: '',
  startWorkTime: null,
  idPhotoZ: '',
  idPhotoF: '',
  idNumber: null,
  autographPhoto: '',
  headPortrait: '',
  gradeCertificatePhoto: '',
  gradeCertificateNumber: null,
  gradeCertificateIssueTime: null,
  certificateOfPracticePhoto: '',
  certificateOfPracticeNumber: null,
  certificateOfPracticePhotoIssueTime: null,
  doctorsCertificatePhoto: '',
  doctorsCertificateNumber: null,
  doctorsCertificateIssueTime: null,
};


/**
 * @description 档案关系
 */
export const relationshipList = ['本人', '妻子', '儿女', '父母', '其他'];


/**
 * @description medical
 */
export const medicalList = [
  { title: '过敏史', options: ['无', '有'] },
  { title: '肝功能', options: ['正常', '异常'] },
  { title: '肾功能', options: ['正常', '异常'] },
  { title: '备孕期', options: ['无', '备孕中'] },
  { title: '哺乳期', options: ['无', '哺乳中'] }];

/**
 * @description 关注病例关注原因关系
 */
export const caseReasonList = ['特殊病例', '诊断待定', '病情变化', '疗效欠佳', '需要随访', '其他原因'];

/**
 * @description 评价tag内容
 */
export const rateTagList = ['业务精湛', '耐心细致', '通俗易懂', '建议中肯', '回复及时'];

export const rateTagDoctorList = ['尊重真诚', '表述清晰', '追问及时', '理解力强', '评价客观'];

/**
 * @description 筛选页排序
 */
export const featureList = [
  { text: '综合医院' },
  { text: '儿童医院' },
  { text: '肿瘤医院' },
  { text: '耳鼻喉科医院' },
  { text: '中西医结合医院' },
  { text: '妇幼保健院' },
  { text: '结核病医院' },
  { text: '传染病医院' },
  { text: '精神病医院' },
  { text: '口腔医院' },
  { text: '胸科医院' },
  { text: '眼科医院' },
];
export const sortList = [
  { text: '默认排序', value: null },
  { text: '接诊人数', value: 'count' },
  { text: '好评率', value: 'avg' },
  { text: '价格从低到高', value: 'price' },
  { text: '响应时间从短到长', value: 'time' },
];
export const titlesList = [
  { text: '主任医师' },
  { text: '副主任医师' },
  { text: '主治医师' },
  { text: '住院医师' },
];
export const typeList = [
  { text: '图文问诊' },
  { text: '图文义诊' },
  // { text: "电话问诊" },
  { text: '诊后咨询' },
  // { text: "续方配药" },
];
export const specialList = [
  { text: '三甲医院' },
  { text: '可开处方' },
  { text: '门诊加号' },
];
export const priceList = [
  { text: '0-30' },
  { text: '31-50' },
  { text: '51-100' },
  { text: '100以上' },
];


/**
 * @description 问诊类型
 */
export const wzData = {
  zh: '诊后咨询',
  dh: '电话问诊',
  yz: '图文义诊',
  xf: '续方配药',
  jh: '门诊加号',
  dm: '图文问诊',
};

/**
 * @description 心意钱数
 */
export const moneyList = [
  { val: '2' },
  { val: '5' },
  { val: '10' },
  { val: '30' },
];

/**
 * @description 身份类型
 */
export const typeCodeList = ['门诊', '住院'];


/**
 * @description 小时时间段
 * @params type 'end' | 'start'
 */

export const hourRangeList = function (type) {
  const timeList = [];
  for (let i = 0; i <= 24; i++) {
    if (i < 10) {
      if (i == 0 && type == 'end') {
        timeList.push({ text: `00:30`, index: 0 });
        continue;
      }

      timeList.push({ text: `0${i}:00`, index: type == 'end' ? 2 * i - 1 : 2 * i }, { text: `0${i}:30`, index: type == 'end' ? 2 * i : 2 * i + 1 });
    } else {
      if (i == 24 && type == 'start') {
        continue;
      }
      if (i == 24 && type == 'end') {
        timeList.push({ text: `24:00`, index: 2 * i - 1 })
        continue;
      }
      timeList.push({ text: `${i}:00`, index: type == 'end' ? 2 * i - 1 : 2 * i }, { text: `${i}:30`, index: type == 'end' ? 2 * i : 2 * i + 1 });
    }

  }

  return timeList;
};


export const getDayList = function (day) {
  const days = [];
  const date = new Date();
  for (let i = 0; i <= 24 * day; i += 24) {		// 144是前六天的小时数
    const dateItem = new Date(date.getTime() + i * 60 * 60 * 1000);	// 使用当天时间戳减去以前的时间毫秒（小时*分*秒*毫秒）
    const y = dateItem.getFullYear();	// 获取年份
    const m = dateItem.getMonth() + 1 > 8 ? dateItem.getMonth() + 1 : `0${dateItem.getMonth() + 1}`;	// 获取月份js月份从0开始，需要+1
    const d = dateItem.getDate() > 9 ? dateItem.getDate() : `0${dateItem.getDate()}`;	// 获取日期

    const valueItem = `${y}-${m}-${d}`;	// 组合
    days.push({ text: valueItem, index: i / 24 });	// 添加至数组
  }

  return days;
};
